












import { defineComponent, ref, useFetch } from "@nuxtjs/composition-api";
import type { CustomForm, CmsPage } from "~/modules/GraphQL/types";
import { useContent, useCustomForm } from "~/composables";
import CustomFormComp from "~/components/CustomForm.vue";
import HTMLContent from "~/components/HTMLContent.vue";

export default defineComponent({
  name: 'ContactUsForm',
  components: {
    HTMLContent,
    CustomFormComp,
  },
  props: {
    identifier: {
      type: String,
      default: '',
    }
  },
  setup() {
    const { loadPage, loading } = useContent();
    const { getCustomFormById } = useCustomForm();

    //// Contact Us Section ////
    const custom_form = ref<CustomForm | null>(null);
    const page = ref<CmsPage | null>(null);
    const { fetch: fetchContactUs } = useFetch(async () => {
      page.value = await loadPage({ identifier: 'contact-us' });
      if (page.value.custom_form_id !== null) {
        custom_form.value = await getCustomFormById({ formId: page.value.custom_form_id });
      }
    });
    const contact_us_cover = ref({});
    contact_us_cover.value = {
      "background-image": 'url(' + require('@/assets/images/contact-us-cover.png') + ')',
      "background-position": "left center",
    }
    fetchContactUs();
    //// Contact Us Section ////

    // @ts-ignore
    return {
      page,
      loading,
      custom_form,
      contact_us_cover
    };
  },
});
